import { Identity, SpecificTracker } from '../types/tracker'
import { SellableProperties } from '../events/types/consumer'
import { UserTraits } from '../types/traits'
import * as Events from '../events'

type GoogleEventParameters = Gtag.CustomParams | Gtag.ControlParams | Gtag.EventParams

// See: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag
interface GtagItemProperties {
  item_id: string
  item_name: string
  affiliation: string
  coupon?: string
  currency: string
  discount?: number
  index?: number
  item_brand?: string
  item_category?: string
  item_category2?: string
  item_category3?: string
  item_category4?: string
  item_category5?: string
  item_list_id?: string
  item_list_name?: string
  item_variant?: string
  location_id?: string
  price?: number
  quantity?: number
}

const enum Destination {
  // Our primary Google Analytics property
  GoogleAnalytics = 'G-BZV3RG7DG3',

  // Our main Google Ads manager account
  //
  // See: https://ads.google.com/home/tools/manager-accounts/
  GoogleAdsManagerAccount = 'AW-829388011',

  // The id associated with our Google Tag Manager container,
  GoogleTagManager = 'GTM-WLQBPM9C',
}

export interface GoogleTrackerOptions {
  debug?: boolean
  gtm?: boolean
}

/**
 * This tracker sends events and conversions to Google products via the Global Site Tag.
 *
 * The two Google products we use today are Google Analytics and Google Ads. We send custom
 *   conversion events to Google Ads for campaign optimization, and e-commerce events to
 *   Google Analytics for product analytics.
 *
 * See: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag
 * And: https://support.google.com/google-ads/answer/7548399?hl=en
 */
export default class GoogleTracker implements SpecificTracker {
  private isGoogleTagManagerEnabled: boolean = false

  public constructor(options: GoogleTrackerOptions = { debug: false, gtm: false }) {
    if (!window.gtag) {
      throw new Error(
        'The Google Ads Pixel snippet is missing, but must be installed to use the GoogleTracker.'
      )
    }

    this.gtag('config', Destination.GoogleAnalytics, {
      debug_mode: !!options.debug,
    })
    this.gtag('config', Destination.GoogleAdsManagerAccount, {
      allow_enhanced_conversions: true,
      debug_mode: !!options.debug,
    })
    if (options.gtm) {
      this.isGoogleTagManagerEnabled = true
    }
  }

  public setPageKey(pageKey?: string) {
    this.gtag('set', Destination.GoogleAnalytics, { content_group: pageKey })
    if (this.isGoogleTagManagerEnabled) {
      // Updates the value for `pageKey` GTM's Data Layer
      // Value is later consumed by triggers configured in GTM
      this.dataLayer.push({ pageKey })
    }
  }

  public get gtag() {
    return window.gtag!
  }

  public get dataLayer() {
    return window.dataLayer || []
  }

  public trackPageViewed({ properties }: Events.PageViewedEvent) {
    if (this.isGoogleTagManagerEnabled) {
      // GTM triggers watch for objects with the `event` key pushed to the dataLayer
      this.dataLayer.push({
        ...properties,
        event: 'gtm.PageViewed',
      })
    }
  }

  public identify(identity: Identity) {
    this.gtag('config', Destination.GoogleAnalytics, {
      user_id: identity.userId,
      consumer_id: identity.consumerId,
      tenant_id: identity.tenantId,
      publisher_id: identity.publisherId,
    })
    this.gtag('config', Destination.GoogleAdsManagerAccount, {
      user_id: identity.userId,
      consumer_id: identity.consumerId,
      tenant_id: identity.tenantId,
      publisher_id: identity.publisherId,
    })
  }

  /**
   * Update the global "enhanced_conversion_data" object, which Google uses for enhanced conversions,
   *   any time the user's traits are modified.
   *
   * TODO: This global object/API is poorly documented. It seems to work but we may want to use the
   *   gtag.js "user_data" API instead: `gtag('set', 'user_data', data)`
   */
  public addTraits(traits: UserTraits) {
    // See: https://support.google.com/google-ads/answer/9888145?hl=en
    window.enhanced_conversion_data = Object.assign(
      window.enhanced_conversion_data || {},
      this.getEnhancedConversionDataFromUserTraits(traits)
    )
  }

  /**
   * Given the user traits, generate an object of fields for Google to use for enhanced conversion
   *    measurement.
   *
   * See: https://support.google.com/google-ads/answer/9888145?hl=en
   *      ("Identify and define your enhanced conversion fields")
   */
  private getEnhancedConversionDataFromUserTraits(
    traits: UserTraits
  ): typeof window.enhanced_conversion_data {
    return {
      email: traits.email,
      phone_number: traits.phone,
      first_name: traits.firstName,
      last_name: traits.lastName,
      home_address:
        // Docs suggest "if your site doesn't collect one of the above fields, remove the field
        //   entirely rather than leaving it blank". It's a bit unclear whether "blank" means the
        //   empty string or undefined, but better safe than sorry.
        traits.address?.city &&
        traits.address.state &&
        traits.address.postalCode &&
        traits.address.country
          ? {
              city: traits.address.city,
              region: traits.address.state,
              postal_code: traits.address.postalCode,
              // TODO: Normalize country to two-letter country code
              country: traits.address.country,
            }
          : undefined,
    }
  }

  private getEcommerceItemDataFromSellable(sellable: SellableProperties): GtagItemProperties {
    return {
      item_id: sellable.sellable_id,
      item_name: sellable.name,
      affiliation: 'Curated',
      currency: sellable.currency,
      item_brand: sellable.brand,
      item_category: sellable.category,
      price: sellable.price ? parseFloat(sellable.price) : undefined,
    }
  }

  /**
   * Track an event to Google Analytics.
   *
   * See: https://developers.google.com/tag-platform/gtagjs/reference/events
   */
  private trackAnalyticsEvent(eventName: string, parameters?: GoogleEventParameters) {
    this.gtag('event', eventName, {
      send_to: Destination.GoogleAnalytics,
      ...parameters,
    })
  }

  /**
   * Track a conversion event to Google Ads.
   *
   * Note: take care to ensure the conversion label is associated with our MCC account.
   */
  private trackAdsConversion(conversionLabel: string, parameters?: GoogleEventParameters) {
    this.gtag('event', 'conversion', {
      send_to: `${Destination.GoogleAdsManagerAccount}/${conversionLabel}`,
      ...parameters,
    })
  }

  public trackProductViewed({ properties }: Events.ProductViewedEvent) {
    // Track a "View Item" event to Google Analytics
    //
    // See: https://developers.google.com/analytics/devguides/collection/ga4/reference/events#view_item
    this.trackAnalyticsEvent('view_item', this.getEcommerceItemDataFromSellable(properties))
  }

  public trackProductsSearchResultsViewed({ properties }: Events.ProductsSearchResultsViewedEvent) {
    // Track a "Search" event to Google Analytics
    //
    // See: https://developers.google.com/analytics/devguides/collection/ga4/reference/events#search
    this.trackAnalyticsEvent('search', {
      search_term: properties.query,
    })
  }

  public trackCheckoutStartedEvent({ properties }: Events.CheckoutStartedEvent) {
    // Track a "Begin Checkout" event to Google Analytics
    //
    // See: https://developers.google.com/analytics/devguides/collection/ga4/reference/events#begin_checkout
    this.trackAnalyticsEvent('begin_checkout', {
      value: parseFloat(properties.total),
      currency: properties.currency,
      coupon: properties.coupon,
      items: properties.products.map((product) => this.getEcommerceItemDataFromSellable(product)),
    })
  }

  public trackOrderCompleted({ properties }: Events.OrderCompletedEvent) {
    // Track a "Purchase" event to Google Analytics
    //
    // See: https://developers.google.com/analytics/devguides/collection/ga4/reference/events#purchase
    this.trackAnalyticsEvent('purchase', {
      transaction_id: properties.order_id,
      affiliation: 'Curated',
      value: parseFloat(properties.total),
      tax: properties.tax ? parseFloat(properties.tax) : undefined,
      shipping: properties.shipping ? parseFloat(properties.shipping) : undefined,
      currency: properties.currency,
      coupon: properties.coupon,
      items: properties.products.map((product) => this.getEcommerceItemDataFromSellable(product)),
    })

    // Track conversion events to Google Ads

    // By default, we use the total order value as the "value"
    const conversionParameters = {
      value: parseFloat(properties.value),
      currency: properties.currency,
      transaction_id: properties.checkout_id,
    }
    // Primary "Order Completed" conversion to the manager ad account
    this.trackAdsConversion('OUJKCPS4sfECEOvpvYsD', conversionParameters)

    // We also track separate events using assumed margin as the "value"
    const conversionParametersWithMargin = Object.assign({}, conversionParameters, {
      value: parseFloat(properties.assumed_margin),
    })
    // Primary "Order Completed" conversion to the manager ad account (using assumed margin as the value)
    this.trackAdsConversion('THKuCNrDl4cZEOvpvYsD', conversionParametersWithMargin)

    if (properties.estimated_cm1 !== null) {
      // We also track separate events using estimated cm1 as the "value"
      const conversionParametersWithCM1 = Object.assign({}, conversionParameters, {
        value: parseFloat(properties.estimated_cm1),
      })
      // Primary "Order Completed (CM1)" conversion to the manager ad account (using estimated cm1 as the value)
      this.trackAdsConversion('YbPGCJf2wM8DEOvpvYsD', conversionParametersWithCM1)
    }

    if (properties.target_margin !== null) {
      // We also track separate events using target margin as the "value"
      const conversionParametersWithTargetMargin = Object.assign({}, conversionParameters, {
        value: parseFloat(properties.target_margin),
      })
      // Primary "Order Completed (Target Margin)" conversion to the manager ad account (using target margin as the value)
      this.trackAdsConversion('VpeRCOLCrOMDEOvpvYsD', conversionParametersWithTargetMargin)
    }

    // Category specific conversions for the manager ad account (using the order total as the value)
    const categorySlugToManagerAdAccountConversionId: { [categorySlug: string]: string } = {
      cycling: '0CNoCLih8f4CEOvpvYsD',
      golf: 'i2GECNLY6_4CEOvpvYsD',
      skiing: '14hvCPDBw_4CEOvpvYsD',
      snowboarding: 'ArtrCK_N6_4CEOvpvYsD',
    }

    properties.products.forEach((product) => {
      if (
        product.marketable_category_slug &&
        product.marketable_category_slug in categorySlugToManagerAdAccountConversionId
      ) {
        this.trackAdsConversion(
          categorySlugToManagerAdAccountConversionId[product.marketable_category_slug],
          conversionParameters
        )
      }
    })

    // Category specific conversions for the manager ad account (using assumed margin as the value)
    const categorySlugToManagerAdAccountConversionIdWithMargin: {
      [categorySlug: string]: string
    } = {
      cycling: 'UIJxCMaChY4DEOvpvYsD',
      golf: '_ZobCMiyuo4DEOvpvYsD',
      skiing: 'eenRCL_7hI4DEOvpvYsD',
      snowboarding: '54HgCK_9hI4DEOvpvYsD',
    }

    properties.products.forEach((product) => {
      if (
        product.marketable_category_slug &&
        product.marketable_category_slug in categorySlugToManagerAdAccountConversionIdWithMargin
      ) {
        this.trackAdsConversion(
          categorySlugToManagerAdAccountConversionIdWithMargin[product.marketable_category_slug],
          conversionParametersWithMargin
        )
      }
    })

    if (this.isGoogleTagManagerEnabled) {
      this.dataLayer.push({
        ...properties,
        event: 'gtm.purchase',
      })
    }
  }

  public trackLeadCreated({ properties }: Events.LeadCreatedEvent) {
    // Track an "Generate Lead" event to Google Analytics
    //
    // See: https://developers.google.com/analytics/devguides/collection/ga4/reference/events#generate_lead
    this.trackAnalyticsEvent('generate_lead')

    // Track conversion events to Google Ads

    // Category agnostic "Lead Created" conversion event
    this.trackAdsConversion('UNQ1CMjgsPECEOvpvYsD')

    // Category agnostic "MQL v0" conversion event
    if (properties.marketing_qualified) {
      this.trackAdsConversion('t3EGCMiV9OcYEOvpvYsD')
    }

    // Category specific "Lead Created" conversion events for the manager ad account
    const categorySlugToManagerAdAccountConversionId: { [categorySlug: string]: string } = {
      cycling: 'HxiOCKKi6P4CEOvpvYsD',
      golf: 'XS_0CPaW6P4CEOvpvYsD',
      skiing: 'vENuCKvuwP4CEOvpvYsD',
      snowboarding: 'XVEKCNX3wP4CEOvpvYsD',
    }

    // Category-specific conversion event
    if (
      properties.marketable_category_slug &&
      properties.marketable_category_slug in categorySlugToManagerAdAccountConversionId
    ) {
      this.trackAdsConversion(
        categorySlugToManagerAdAccountConversionId[properties.marketable_category_slug]
      )
    }
  }

  public trackCuratedListViewed({ properties }: Events.CuratedListViewedEvent) {
    if (!properties.first_view) {
      return
    }

    const categorySlugToManagerAdAccountConversionId: { [categorySlug: string]: string } = {
      cycling: 'hyoZCOLK6P4CEOvpvYsD',
      golf: 'FbfmCP_87f4CEOvpvYsD',
      skiing: 'wZAZCKm-6P4CEOvpvYsD',
      snowboarding: 'B4ACCJvC6P4CEOvpvYsD',
    }

    // Category-agnostic "Curated List Viewed" conversion event
    this.trackAdsConversion('EIvTCN704_4CEOvpvYsD')

    // Category-specific "Curated List Viewed" conversion events
    properties.items.forEach((item) => {
      if (
        item.marketable_category_slug &&
        item.marketable_category_slug in categorySlugToManagerAdAccountConversionId
      ) {
        this.trackAdsConversion(
          categorySlugToManagerAdAccountConversionId[item.marketable_category_slug]
        )
      }
    })
  }

  public trackProductAdded({ properties }: Events.ProductAddedEvent) {
    // Track an "Add To Cart" event to Google Analytics
    //
    // See: https://developers.google.com/analytics/devguides/collection/ga4/reference/events#add_to_cart
    this.trackAnalyticsEvent('add_to_cart', {
      ...this.getEcommerceItemDataFromSellable(properties),
      quantity: properties.quantity,
    } as GtagItemProperties)

    // Track conversion events to Google Ads
    const conversionParameters = {
      ecomm_pagetype: 'product',
      ecomm_prodid: properties.product_id,
      ecomm_totalvalue: properties.price,
      ecomm_category: properties.category,
      dynx_itemid: [properties.product_id],
      dynx_pagetype: 'conversionintent',
      dynx_totalvalue: properties.price,
    }

    // Category agnostic "Product Added" conversion event
    this.trackAdsConversion('qkKyCOjo6P4CEOvpvYsD', conversionParameters)

    // Category specific "Product Added" conversion event
    const categorySlugToManagerAdAccountConversionId: { [categorySlug: string]: string } = {
      cycling: '-Ah9CNzkwf4CEOvpvYsD',
      golf: 'aHXVCIj06P4CEOvpvYsD',
      skiing: 'dIeXCMC17v4CEOvpvYsD',
      snowboarding: 'SAWnCOiz7v4CEOvpvYsD',
    }

    if (
      properties.marketable_category_slug &&
      properties.marketable_category_slug in categorySlugToManagerAdAccountConversionId
    ) {
      this.trackAdsConversion(
        categorySlugToManagerAdAccountConversionId[properties.marketable_category_slug]
      )
    }

    if (this.isGoogleTagManagerEnabled) {
      this.dataLayer.push({
        ...properties,
        event: 'gtm.addToCart',
      })
    }
  }

  public trackProductAddedToWishlist({ properties }: Events.ProductAddedToWishlistEvent) {
    // Track an "Add To Wishlist" event to Google Analytics
    //
    // See: https://developers.google.com/analytics/devguides/collection/ga4/reference/events#add_to_wishlist
    this.trackAnalyticsEvent('add_to_wishlist', this.getEcommerceItemDataFromSellable(properties))

    // Track conversion events to Google Ads
    const conversionParameters = {
      ecomm_pagetype: 'product',
      ecomm_prodid: properties.product_id,
      ecomm_totalvalue: properties.price,
      ecomm_category: properties.category,
      dynx_itemid: [properties.product_id],
      dynx_pagetype: 'conversionintent',
      dynx_totalvalue: properties.price,
    }

    // Category agnostic "Product Added" conversion event
    this.trackAdsConversion('qkKyCOjo6P4CEOvpvYsD', conversionParameters)

    // Category specific "Product Added" conversion event
    const categorySlugToManagerAdAccountConversionId: { [categorySlug: string]: string } = {
      cycling: '-Ah9CNzkwf4CEOvpvYsD',
      golf: 'aHXVCIj06P4CEOvpvYsD',
      skiing: 'dIeXCMC17v4CEOvpvYsD',
      snowboarding: 'SAWnCOiz7v4CEOvpvYsD',
    }

    if (
      properties.marketable_category_slug &&
      properties.marketable_category_slug in categorySlugToManagerAdAccountConversionId
    ) {
      this.trackAdsConversion(
        categorySlugToManagerAdAccountConversionId[properties.marketable_category_slug]
      )
    }
  }
}
